// variables & functions
@import 'variables';

// base & layout
@import 'base';

// colors

// components (table)
@import 'components/card';
@import 'components/table';
@import 'components/timeline';
@import 'components/nav';
@import 'components/swiper';
@import 'components/contact';
@import 'components/about-me';
@import 'components/specialization';
@import 'components/festival';
@import 'components/offer';
@import 'components/footer';
@import 'components/header';
@import 'components/button';
@import 'components/form';
@import 'components/alert';

// utilites (margin, padding, opacity)
img {
  border-radius: $base-border-radius;
}

li {
  list-style: none;
}

.stats {
  @media (min-width: 768px) {
    margin: 160px auto;
  }
}

h2 {
  font-size: 26px;
  margin-bottom: 20px;
  cursor: default;

  @media (min-width: 1440px) {
    font-size: 42px;
    margin-top: 160px;
  }
}

p,
li {
  font-size: 18px;

  @media (min-width: 1440px) {
    font-size: 20px;
  }
}

.title,
.subtitle {
  color: $gold;
}

.subtitle {
  &--offer {
    @media (min-width: 768px) {
      margin: 60px auto;
    }
  }
}

.title {
  @media (min-width: 768px) {
    margin-bottom: 60px;
  }

  @media (min-width: 1440px) {
    font-size: 42px;
    margin-top: 160px;

    &--wszystko-trwa {
      margin-top: 0;
    }
  }
}

.gold {
  color: $gold;
  font-weight: 700;
}

.disable-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}