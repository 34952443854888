.specialization {

  @media (min-width: 768px) {
    &-wrapper {
      display: flex;
      column-gap: 20px;
      margin-top: 60px;

      div {
        width: 60%;
      }

      p {
        width: 90%;
      }
    }
  }


  @media (min-width: 768px) {
    &-wrapper {
      display: flex;
      column-gap: 20px;
      margin-top: 60px;
    }
  }

  @media (min-width: 1024px) {
    &-wrapper {
      display: flex;
      column-gap: 50px;
    }
  }

  @media (min-width: 1440px) {
    &-wrapper {
      display: flex;
      column-gap: 80px;
    }
  }

  p {
    margin-bottom: 15px;
  }
}