 .swiper {
   width: 100%;
   height: 100%;
 }

 .swiper-slide {
   text-align: center;
   font-size: 18px;
   background: transparent;
   /* Center slide text vertically */
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   -webkit-align-items: center;
   align-items: center;
 }

 .swiper-slide img {
   display: block;
   width: 100%;
   height: 100%;
   object-fit: contain;
 }

 .gallery {
   width: 50%;
   display: flex;

   @media screen and (max-width:767px) {
     width: 100%;
   }
 }

 div.swiper-button-prev,
 div.swiper-button-next {
   color: $gold;

 }

 div.swiper-button-prev {
   left: 30px;
 }

 div.swiper-button-next {
   right: 30px;
 }