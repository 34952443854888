@media (min-width: 768px) {
  .contact {
    display: flex;
    column-gap: 50px;
  }

  .costs {
    display: flex;
    flex-direction: column;
  }
}

.costs {

  @media screen and (min-width: 1366px) {
    width: 50%;
  }

  &__contact {
    justify-content: center;
  }
}

.contact-interest {
  margin-bottom: 15px;
  color: $white;
  font-size: 22px;

  @media (max-width: 768px) {
    font-size: 18px;

  }
}

.contact-me {
  font-size: 60px;
  color: $gold;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 32px;
  }
}

.contact__form {
  column-gap: 30px;
}

.remember-list {
  margin: 10px 0;
}