footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  a:nth-child(2) {
    margin-top: 6px;
  }

  p {
    width: 100%;
    margin: 0px auto;
    text-align: center;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.copyright {
  p {
    text-align: center;
    margin: 10px auto 15px;
  }
}

.map-link {
  color: $gold;
  font-weight: 700;
}

.copyright {
  p {
    text-align: center;
    margin: 10px auto 15px;
  }
}