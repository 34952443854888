.modal--success,
.modal--error {
  display: none;
  align-items: center;
  gap: 10px;
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
  border-radius: 5px;
  padding-left: 20px;
  color: $white;
}

.modal--success {
  background-color: #23DB72;
}

.modal--error {
  background-color: #E44B3C;
}

.active {
  display: flex
}