header {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.header__button {
  position: fixed;
  z-index: 12;
  width: 40px;
  height: 40px;
  border-radius: 20%;
  background-color: $gold;
  border: 0;
  cursor: pointer;
  right: 0;
  margin: 40px;
  outline: none;
  animation-duration: 300ms;
  animation-timing-function: ease;
  color: $black;

  @media screen and (max-width:425px) {
    margin: 30px 20px;
  }

  &:hover,
  &:focus {
    transition: .2s;
    transform: scale(.9);
  }
}

.header__button[data-open="true"] {
  background-color: #000;
  animation-name: scale;
}

.header__button[data-open="false"] {
  transition: background-color 250ms linear;
}

@keyframes scale {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(100%);
  }
}

h1 {
  font-size: 32px;
  font-weight: normal;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.header__nav {
  background-color: $gold;
  position: fixed;
  overflow: hidden;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  visibility: hidden;
  clip-path: circle(var(--radius) at calc(100% - 55px) 47px);
}

.header__nav[data-active="true"] {
  visibility: visible;
}

.header__menu {
  padding: 0;
  margin: 0;
}

.header__menu>.header__menu-item {
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 18px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 30px;
  }

}

.header__menu-item a {
  color: #000;
  text-decoration: none;
}

.nav-desktop {
  @media screen and (min-width: 1366px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    row-gap: 20px;
    position: fixed;
    margin: 40px;
    bottom: 250px;
    right: 0;

    a {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      font-size: 18px;
      color: $white;
    }
  }
}