.cards {
  display: flex;
  row-gap: 25px;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  column-gap: 25px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    column-gap: 50px;
    row-gap: 50px;
  }
}

.card {
  width: 100%;
  max-width: 202px;
  background-color: $gold;
  border-radius: $base-border-radius;
  text-align: center;
  min-height: 200px;
  cursor: default;


  @media (max-width: 768px) {
    max-width: 150px;
    height: 260px;
  }

  @media (max-width: 375px) {
    max-width: 140px;
  }

  p {
    margin: 32px 0 48px;
    color: $black;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 768px) {
      margin-bottom: 0px;

    }

    @media (min-width: 1440px) {
      font-size: 16px;
    }
  }

  .icon {
    margin: 30px 0px 5px;

    @media (max-width: 768px) {
      transform: scale(.8);
      margin: 10px 0px 5px;
    }
  }

  .number {
    position: relative;

    p {
      z-index: 10;
      position: relative;
    }

    .current {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .circle {
      position: absolute;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background-color: $white;
      top: -16px;
      left: 75px;
      z-index: 1;

      @media (max-width: 768px) {
        left: 50px;
      }

      @media (max-width: 375px) {
        left: 44px;
      }
    }
  }
}