// theme colors
$gold: rgb(255, 230, 0);
$lighter-gold: rgba(255, 230, 0, .1);
$black: #141414;
$lighter-black: rgba(20, 20, 20, 0.8);
$white: rgba(255, 255, 255, 1);
$lighter-white: rgba(255, 255, 255, 0.2);
$lighter-white-40: rgba(255, 255, 255, 0.4);
// spacing
$base-padding: 0.75rem;
$base-margin: 0.75rem;

// borders
$base-border-thickness: 1px;
$base-border-radius: 20px;