.table {
  border-top: 1px solid $lighter-white;
  border-bottom: 1px solid $lighter-white;
  margin: 20px 0;
  cursor: default;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 160px;
  }

}

.row {
  padding: 15px 0;
  border-top: 1px solid $lighter-white;

  &:nth-child(even) {
    background-color: $lighter-gold;
  }

  @media (min-width: 768px) {
    flex: 50%;

    &:nth-child(even) {
      border-left: 1px solid $lighter-white;
      background-color: rgba(255, 255, 255, 0);
    }

    &:nth-child(2) {
      background-color: $lighter-gold;
    }

    &:nth-child(3) {
      background-color: $lighter-gold;
    }

    &:nth-child(6) {
      background-color: $lighter-gold;
    }

    &:nth-child(7) {
      background-color: $lighter-gold;
    }

    &:nth-child(10) {
      background-color: $lighter-gold;
    }

    &:nth-child(11) {
      background-color: $lighter-gold;
    }

    &:nth-child(14) {
      background-color: $lighter-gold;
    }

    &:nth-child(15) {
      background-color: $lighter-gold;
    }

    &:nth-child(18) {
      background-color: $lighter-gold;
    }
  }

  &:hover {
    background-color: $gold;
    transition: 0.2s;
    color: $black;
    font-weight: 700;

    p,
    span {
      color: $black;
      font-weight: 600;
    }
  }

  p {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;

    @media (min-width: 768px) {
      margin-left: 20px;
    }
  }

  span {
    font-size: 16px;
  }

  ul li {
    margin-left: 10px;

    @media (min-width: 768px) {
      margin-left: 20px;
    }
  }
}