.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 44px;
  position: relative;

  @media screen and (max-width:768px) {
    justify-content: center;
    column-gap: 20px;
  }

  a {
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    max-width: 290px;
    text-decoration: none;
    color: $gold;
    border: 1px solid $gold;

    &:hover,
    &:focus {
      background-color: $gold;
      transition: 0.3s;
      color: $black;
      font-weight: 700;
    }

    @media screen and (max-width:768px) {
      max-width: 150px;
    }
  }

  a div {
    padding: 8px 0;
  }
}