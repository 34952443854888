.masthead {
  text-align: center;
  color: white;
  background-image: url("../img/header.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;

  @media screen and (max-width:768px) {
    background-image: url("../img/header-mobile.jpg");
  }

  .masthead {
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      padding-bottom: 100px;

      @media screen and (max-width:768px) {
        padding-bottom: 50px;
      }
    }

    &__text {
      margin-bottom: 40px;
      font-weight: 700;

      @media screen and (max-width:768px) {
        font-size: 40px;
      }

      p:nth-child(2) {
        font-size: 72px;
        font-weight: 400;

        @media screen and (max-width:768px) {
          font-size: 42px;
        }

        @media screen and (max-width:375px) {
          font-size: 38px;
        }
      }

      p:nth-child(1) {
        font-size: 62px;
        font-weight: 400;

        @media screen and (max-width:768px) {
          font-size: 36px;
        }

        @media screen and (max-width:375px) {
          font-size: 32px;
        }
      }
    }
  }
}

.logo {

  position: fixed;
  margin: 40px;
  top: 0;

  @media screen and (max-width: 768px) {
    position: absolute;
    left: 0;
  }
}

.social-media {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: fixed;
  margin: 40px;
  bottom: 0;

  a {

    &:hover,
    &:focus {
      path {
        fill: $gold;
        transition: .2s;
        transform: scale(.9);
      }
    }
  }

  @media screen and (max-width:768px) {
    display: none;
  }
}

.header__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 100px;



  @media screen and (max-width:1920px) {
    padding-top: 40px;
  }

  @media screen and (max-width:1440px) {
    padding-top: 60px;
  }

  @media screen and (max-width:1366px) {
    padding-top: 50px;
  }

  @media screen and (max-width:768px) {
    padding-top: 90px;
  }

  @media screen and (max-width:375px) {
    padding-top: 80px;
  }

}

.header {

  &__label {
    position: relative;
    margin-bottom: 3px;
    animation-name: fade-up;
    animation-duration: 1.3s;
    animation-delay: 1.5s;
    animation-fill-mode: both;

    @media screen and (max-width:1366px) {
      font-size: 16px;
    }

  }

  &__label:nth-child(2) {
    animation-delay: 1.6s;
  }

  &__label:nth-child(3) {
    animation-delay: 1.7s;
  }

  &__label:nth-child(4) {
    animation-delay: 1.8s;
  }

  &__label:nth-child(5) {
    animation-delay: 1.9s;
  }

  &__label:nth-child(6) {
    animation-delay: 2s;
  }
}

@keyframes fade-up {
  from {
    top: 100px;
    opacity: 0
  }

  to {
    top: 0px;
    opacity: 1
  }

}