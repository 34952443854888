.place {
  font-size: 18px;
  margin-bottom: 5px;
}

.city {
  font-size: 24px;
  margin-bottom: 3px;
}

.type {
  font-size: 16px;
  margin-bottom: 5px;
  color: $lighter-white-40;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
  margin-top: 50px;
  margin-bottom: 50px;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 52px;
  width: 2px;
  margin-left: -1.5px;
  content: '';
  background-color: $lighter-white;
}

.timeline>li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline>li:after,
.timeline>li:before {
  display: table;
  content: ' ';
}

.timeline>li:after {
  clear: both;
}

.timeline>li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
  cursor: default;
}

.timeline>li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li .timeline-image {
  position: absolute;
  z-index: 7;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  border-radius: 100%;
}

.timeline>li .timeline-image h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline>li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4 {
  margin-top: 0;
}

.timeline .timeline-heading h4.subheading {
  text-transform: none;
}

.timeline .timeline-body>ul,
.timeline .timeline-body>p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 51%;
  }

  .timeline>li {
    min-height: 100px;
    margin-bottom: 100px;
  }

  .timeline>li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }

  .timeline>li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }

  .timeline>li .timeline-image h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline>li {
    min-height: 150px;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px;
  }

  .timeline>li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }

  .timeline>li .timeline-image h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline>li {
    min-height: 170px;
  }

  .timeline>li .timeline-panel {
    padding: 0 20px 20px 100px;
  }

  .timeline>li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }

  .timeline>li .timeline-image h4 {
    margin-top: 40px;
  }

  .timeline>li.timeline-inverted>.timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.hidden {
  display: none;
}

// ----------------------------------------------------------------

// section#contact .form-group {
//   margin-bottom: 25px;
// }

// section#contact .form-group input,
// section#contact .form-group textarea {
//   padding: 20px;
// }

// section#contact .form-group input.form-control {
//   height: auto;
// }

// section#contact .form-group textarea.form-control {
//   height: 248px;
// }

// section#contact .form-control:focus {
//   border-color: #fed136;
//   -webkit-box-shadow: none;
//   box-shadow: none;
// }