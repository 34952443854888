input,
textarea,
.submit {
  margin: 15px 0;
  width: 100%;
  padding: 8px 0px 8px 11px;
  border: 1px solid $lighter-gold;
  background-color: transparent;
  color: $gold;

  &::placeholder {
    color: $lighter-gold;
  }
}

.submit {
  font-size: 22px;
  cursor: pointer;
}

.form {
  margin-bottom: 100px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
}