* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Space Grotesk', sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: $black;
}

// * {
//   touch-action: none !important;
// }

::-moz-selection {
  /* Code for Firefox */
  color: $black;
  background: $gold;
}

::selection {
  color: $black;
  background: $gold;
}

p,
li {
  color: $white;
}

ul {
  padding: 0;
  list-style-type: none;
}

// li {
//   margin-left: 10px;
// }

a {
  text-decoration: none;
}

.img {
  object-fit: contain;
  margin: 25px 0 50px 0;
  height: 100%;
  width: 100%;

  &--festival {
    max-height: 733px;

    @media (max-width: 768px) {
      transform: scale(.9);
    }
  }

  &--about {
    max-height: 508px;
    height: auto;

    @media (max-width: 768px) {
      transform: scale(.9);
    }
  }

  &--training {
    transform: scale(.9);

    @media (min-width: 768px) {
      height: auto;
      width: auto;
    }
  }
}

h3 {
  margin-top: 20px;

  @media (min-width: 1440px) {
    font-size: 32px;
  }
}

.t-center {
  text-align: center;
}

section {
  width: 90%;
  margin: 0px 5%;

  @media (min-width: 1024px) {
    width: 80%;
    margin: 0px 10%;
  }

  @media (min-width: 1440px) {
    width: 70%;
    margin: 0px 12.5%;
  }
}

.color {
  background-color: $lighter-black;
  padding-top: 20px;
}

.flex {
  &--center {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 768px) {
    width: 100%;
    margin: 0px auto;
  }
}