.about-me {
  &-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      column-gap: 50px;
    }

    @media (min-width: 1024px) {
      column-gap: 130px;
      margin-top: 80px;

    }

    @media (min-width: 1440px) {
      margin-top: 140px;
    }

    @media (min-width: 1920px) {
      column-gap: 170px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  h2,
  p {
    margin-top: 20px;
  }

  p,
  li {
    margin-bottom: 10px;
  }
}