.festival {
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 150px;
  }

}