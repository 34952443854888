.offer {
  &-desc {
    margin-bottom: 10px;
  }

  .text {
    margin-top: 10px;

    @media (min-width: 1366px) {
      width: 55%;
    }
  }

  &-list {
    margin-bottom: 15px;

    li {
      margin-bottom: 10px;
    }
  }
}